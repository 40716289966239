import "./App.css";
import Login from "./components/login/Login";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import ActionNotification from "./components/ui/ActionNotification";
import Spinner from "./components/ui/Spinner";
import PrivateRoute from "./util/PrivateRoute";
import Home from "./components/home/Home";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/login" exact component={Login} />
          <PrivateRoute path="/" component={Home} />
        </Switch>
      </BrowserRouter>
      <ActionNotification />
      <Spinner />
    </div>
  );
}

export default App;
